
export default {
  name: 'WhatsAppChannel',
  components: { },
  data () {
    return {
    }
  },
  computed: {},
  created () {},
  mounted () {},
  methods: {
  },
}
